import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTextDomainContext} from "../../providers";
import {isEqual} from "lodash";

export const SimpleDropdown = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  let cacheKey = "SimpleDropdown";
  if (props.componentLabel) {
    cacheKey = `SimpleDropdown-${props.componentLabel}`;
  }

  const [options, setOptions] = useState([
    {
      value: "",
      label: gettext("Select")
    }
  ]);
  const [selectSize, setSelectSize] = useState("medium");

  const [selectedValue, setSelectedValue] = useState(
    // localFilters ? localFilters.buildingMeterValue : gettext("all")
    ""
  );


  const handleChangeSelectedValue = (value) => {
    if (cacheKey && value) {
      localStorage.setItem(cacheKey, value);
    }
    setSelectedValue(value);
  };

  const handleChange = event => {
    const selectedObject = options.find(v => v.value === event.target.value);
    // setSelectedValue(event.target.value);
    handleChangeSelectedValue(event.target.value);
    // handleChangeSelectedValue(event.value);
    // setSelectedLabel(event.label);
    props.onChange(selectedObject);

    // (event) => {
    //   props.onChange(options.find(v => v.value === event.target.value));
    // }
  };

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    setSelectSize(props.size);
  }, [props.size]);

  useEffect(() => {
    if (selectedValue) {
      const defaultIndex = options.findIndex(
        x => x.value === selectedValue
      );
      if (defaultIndex > -1) {
        const selected = options[defaultIndex];
        if (typeof props.onChange === "function") {
          props.onChange(selected);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, selectedValue]);

  useEffect(() => {
    const cacheValue = localStorage.getItem(cacheKey);
    if (props.defaultValue) {
      const defaultIndex = props.options.findIndex(
        x => x.value === props.defaultValue
      );
      if (defaultIndex > -1) {

        const selected = props.options[defaultIndex];

        if (cacheValue) {
          let selectedObject = null;
          if (isEqual(options, props.options)) {
            selectedObject = options.find(v => v.value === cacheValue);
          }

          if (!selectedObject && options?.length) {
            selectedObject = options[0];
          }

          if (selectedObject?.value) {
            setSelectedValue(selectedObject?.value);
          }
        } else {
          setSelectedValue(selected.value);
        }

        if (typeof props.onChange === "function") {
          props.onChange(selected);
        }
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue, props.options, options]);

  return (
    <FormControl fullWidth>
      <InputLabel id="select-label">{props.componentLabel}</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={selectedValue}
        fullWidth
        onChange={handleChange}
        variant="outlined"
        size={selectSize}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

};