import {useQuery} from "@apollo/client";
import {TAXES_INVOICES} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 *
 * @param variables
 * @param onCompleted
 * @param onError
 */
export function useTaxesInvoices(
  variables,
  onCompleted = () => {
  },
  onError = () => {

  }
) {
  const {
    data,
    loading
  } = useQuery(TAXES_INVOICES, {
    variables,
    onCompleted: ({climateData}) => {
      onCompleted(climateData);
    },
    onError: (err) => {
      onError();

      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });

  return {
    data: data?.taxesInvoice || [],
    loading
  };
}
