import {initIDB} from "../../app/common/IndexedDb";
import {indexedDbGetById} from "./indexedDbGetById";

export const indexedDbGetOnePageData = (
  dbName = "cache",
  key = "",
  paginationModel = {
    page: 0,
    pageSize: 10,
  },
  sortModel = [],
  filterModel = {
  }
) => {
  return new Promise((resolve, reject) => {
    initIDB().then(db => {
      indexedDbGetById(dbName, key).then(result => {
        let rowCount = 0;
        if (!Array.isArray(result)) {
          resolve([]);
          return;
        }

        let filteredData = result;

        // Apply filtering based on filterModel
        if (Array.isArray(filterModel.items) && filterModel.items.length > 0) {
          filteredData = filteredData.filter(item => {
            return filterModel.items.every(filter => {
              const {field, operator, value} = filter;
              const fieldValue = item[field] !== undefined && item[field] !== null ? item[field].toString().trim() : "";
              const trimmedValue = typeof value === "string" ? value.trim() : value;

              switch (operator) {
                case "contains":
                  return fieldValue.includes(trimmedValue);
                case "doesNotContain":
                  return !fieldValue.includes(trimmedValue);
                case "equals":
                  return fieldValue === trimmedValue;
                case "doesNotEqual":
                  return fieldValue !== trimmedValue;
                case "startsWith":
                  return fieldValue.startsWith(trimmedValue);
                case "endsWith":
                  return fieldValue.endsWith(trimmedValue);
                case "isEmpty":
                  return fieldValue === "";
                case "isNotEmpty":
                  return fieldValue !== "";
                case "isAnyOf":
                  return Array.isArray(trimmedValue) && trimmedValue.includes(fieldValue);
                default:
                  return true;
              }
            });
          });
        }

        // Apply sorting if `sortModel` is provided
        let sortedData = filteredData;
        if (Array.isArray(sortModel) && sortModel.length > 0) {
          sortedData = filteredData.sort((a, b) => {
            for (const criterion of sortModel) {
              const {field, sort: order} = criterion;
              const fieldA = a[field];
              const fieldB = b[field];

              if (fieldA === undefined || fieldB === undefined) continue;

              let comparison = 0;
              if (typeof fieldA === 'string' && typeof fieldB === 'string') {
                comparison = order === "asc" ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
              } else if (typeof fieldA === 'number' && typeof fieldB === 'number') {
                comparison = order === "asc" ? fieldA - fieldB : fieldB - fieldA;
              }

              if (comparison !== 0) return comparison;
            }
            return 0;
          });
        }

        rowCount = sortedData.length;

        // Apply pagination to the sorted data
        const currentData = sortedData.slice(
          paginationModel.page * paginationModel.pageSize,
          (paginationModel.page + 1) * paginationModel.pageSize
        );

        resolve({
          data: currentData,
          rowCount,
        });
      }).catch(err => {
        resolve({
          data: [],
          rowCount: 0,
        });
      });
    });
  });
};
