import {Autocomplete, TextField} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {useTextDomainContext} from "../../providers";
import PropTypes from "prop-types";
import {isEqual} from "lodash";

export const AutocompleteDropdown = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  let cacheKey = "AutocompleteDropdown";
  if (props.componentLabel) {
    cacheKey = `AutocompleteDropdown-${props.componentLabel}`;
  }

  const [options, setOptions] = useState([
    {
      value: "",
      label: gettext("Select")
    }
  ]);
  const [selectSize, setSelectSize] = useState("medium");
  const [selectedValue, setSelectedValue] = useState(
    null
  );

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    setSelectSize(props.size);
  }, [props.size]);

  useEffect(() => {
    // Calculate the maximum width of options
    const maxWidth = Math.max(...options.map(option => option.label.length));

    // Set a dynamic width based on the maximum label length
    const dynamicWidth = maxWidth * 5 + 100; // Adjust the multiplier and offset as needed

    // Apply the dynamic width to the Autocomplete element
    const autocompleteElement = document.getElementById('autocomplete-dropdown');
    autocompleteElement.style.width = `${dynamicWidth}px`;
  }, [options]);

  useEffect(() => {
    if (selectedValue) {
      const defaultIndex = options.findIndex(
        x => x.value === selectedValue
      );
      if (defaultIndex > -1) {
        const selected = options[defaultIndex];
        if (typeof props.onChange === "function") {
          props.onChange(selected);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, selectedValue]);

  useEffect(() => {
    const cacheValue = localStorage.getItem(cacheKey);
    if (props.defaultValue) {
      const defaultIndex = props.options.findIndex(
        x => x.value === props.defaultValue
      );
      if (defaultIndex > -1) {

        const selected = props.options[defaultIndex];

        if (cacheValue) {
          let selectedObject = null;
          if (isEqual(options, props.options)) {
            selectedObject = options.find(v => v.value === cacheValue);
          }

          if (!selectedObject && options?.length) {
            selectedObject = options[0];
          }

          if (selectedObject?.value) {
            setSelectedValue(selectedObject);
          }
        } else {
          setSelectedValue(selected);
        }

        if (typeof props.onChange === "function") {
          props.onChange(selected);
        }
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue, props.options, options]);

  const handleChangeSelectedValue = (value) => {
    if (cacheKey && value) {
      localStorage.setItem(cacheKey, value);
    }
    setSelectedValue(value);
  };

  const handleChange = (event, value) => {
    // const selectedObject = options.find(v => v.value === event.target.value);
    // setSelectedValue(event.target.value);
    handleChangeSelectedValue(value);
    // handleChangeSelectedValue(event.value);
    // setSelectedLabel(event.label);
    props.onChange(value);

    // (event) => {
    //   props.onChange(options.find(v => v.value === event.target.value));
    // }
  };

  return (

    <Autocomplete
      id="autocomplete-dropdown"
      size={selectSize}
      options={options}
      sx={{
        ...props.sx
      }}
      value={selectedValue}
      onChange={handleChange}
      // getOptionLabel={(option) => {
      //   console.log(option);
      //   return option.label;
      // }}
      // getOptionLabel={(option) => option.label}
      // getOptionKey={(option) => option.value}
      // defaultValue={companies.find(option => option.value === customerValue) || null}
      // value={companies.find(option => option.value === customerValue) || null}
      disableClearable
      // onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label="Companies" placeholder="Companies"/>
      )}
    />
  );
};

AutocompleteDropdown.propTypes = {
  size: PropTypes.oneOf(["small", "medium"]),
  sx: PropTypes.object,
  // componentLabel: PropTypes.string,
  // defaultValue: PropTypes.string,
  // onChange: PropTypes.func,
};