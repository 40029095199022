import {TableView1} from "../../components";
import React, {useContext, useEffect, useState} from "react";
import {useLang, useTextDomainContext, useUser} from "../../providers";
import * as PropsType from "prop-types";
import {energyMonthlyColumns,
  energyStatementKey,
  getMonthFromString,
  getMonthsArray,
  indexedDbGetById,
  indexedDbGetOnePageData} from "../../../utils";

export const EnergyMonthlyStatement = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const locale = useLang();
  const {user} = useUser();
  let {
    currentTeam: customer,
    currentSubsidiary: group,
  } = user || {
  };

  if (!customer) {
    customer = "all";
  }

  if (!group) {
    group = "All";
  }

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 10
      }
    }
  };

  //#region React Hooks (useState)

  const propsDefault = {
    title: gettext("One Month Energy Consumption (kWh)"),
    rowCount: -1,
  };
  const [fileNameForExport, setFileNameForExport] = useState("energy-monthly-statement");
  const [title, setTitle] = useState(propsDefault.title);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(props.rows);
  const [rowCount, setRowCount] = useState(typeof props.rowCount === "number" ? props.rowCount : propsDefault.rowCount);
  const [localLoading, setLocalLoading] = useState(false);
  const [fullMonthName, setFullMonthName] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filterModel, setFilterModel] = useState({
    items: []
  });
  const [sortModel, setSortModel] = useState([]);
  const [filters, setFilters] = useState({
  });
  const [infoText, setInfoText] = useState("");

  //#endregion React Hooks (useState)

  //#region React Hooks (useEffect)

  useEffect(() => {
    setFilters({
      meterType: props.meterType,
      customer: customer,
      group: group,
      year: props.year,
      isClimate: props.isClimate,
      month: getMonthFromString(props.month),
    });
  }, [customer, group, props.isClimate, props.meterType, props.month, props.year]);

  useEffect(() => {
    setInfoText(props.infoText);
  }, [props.infoText]);

  useEffect(() => {
    setRowCount(props.rowCount);
  }, [props.rowCount]);

  useEffect(() => {
    setFileNameForExport(props.fileNameForExport);
  }, [props.fileNameForExport]);

  useEffect(() => {
    const monthList = getMonthsArray(gettext);
    if (props.month === "all") {
      setFullMonthName(gettext("Full Year"));
    } else {
      setFullMonthName(monthList.find(obj => obj.value === props.month).label);
    }
  }, [gettext, props.month]);

  useEffect(() => {
    if (props.isClimate) {
      setTitle(fullMonthName + " " + gettext("CO₂e (Kg)"));
    } else {
      setTitle(fullMonthName + " " + gettext("Energy Consumption (kWh)"));
    }
  }, [fullMonthName, gettext, props.isClimate]);

  useEffect(() => {
    const newFilter = filters;
    delete newFilter.month;
    const keyMonthData = energyStatementKey("monthData4Columns", newFilter);
    if (filters?.customer) {
      indexedDbGetById("cache", keyMonthData).then((result) => {
        const columnsData = energyMonthlyColumns(result, gettext, locale);
        setColumns(columnsData);
      }).catch();
    }
  }, [filters, gettext, locale, props.rows]);

  useEffect(() => {
    setLocalLoading(true);
    if (filters?.meterType) {
      const key = energyStatementKey(props.month, filters);
      indexedDbGetOnePageData("cache", key, paginationModel, sortModel, filterModel).then((result) => {
        if (result?.data?.length) {
          setRowCount(result.rowCount);
          setRows(result.data);
        } else if (!props.loading) {
          if (result?.data) {
            setRowCount(result.rowCount);
            setRows(result.data);
          } else {
            setRowCount(0);
            setRows([]);
          }
        }
        setLocalLoading(false);
      }).catch();
    }
  }, [filterModel, filters, paginationModel, props.loading, props.month, sortModel, props.rows]);

  //#endregion React Hooks (useEffect)

  //#region Handlers

  const handleChangeEnergyStatement = (obj) => {
    if (typeof props.onChangeEnergyStatement === "function") {
      props.onChangeEnergyStatement(obj);
    }
  };

  //#endregion Handlers

  return (
    <>
      {/*{columns.length > 0 && (*/}
      <TableView1
        title={title}
        // rows={metersData}
        rows={rows}
        // rows={[]}
        rowCount={rowCount}
        columns={columns}
        // loading={loading || localLoading}
        loading={localLoading}
        initialState={initialState}
        showGridToolbar
        // showEnergyStatementType
        showGridToolbarEnergyStatementExport
        // showGridToolbarQuickFilter
        fileNameForExport={fileNameForExport}
        excelData={props.excelData}
        onChangeEnergyStatement={handleChangeEnergyStatement}
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setSortModel}
        onFilterModelChange={setFilterModel}
        dataMode="server"
        infoText={infoText}
        // selectedEnergyStatement="monthly-statement"
      />
      {/*)}*/}
      {/*{loading && (*/}
      {/*  TableLoadingStateEnergyStatement()*/}
      {/*  // gettext("Loading")*/}
      {/*)}*/}
      {/*{!loading && !data?.consumptionEnergyData?.consumptionData?.length && (*/}
      {/*  TableLoadingStateEnergyStatement()*/}
      {/*  // gettext("Still Loading")*/}
      {/*)}*/}

      {/*{!loading && data?.consumptionEnergyData?.consumptionData.length > 0 && !rows.length && (*/}
      {/*  TableLoadingStateEnergyStatement()*/}
      {/*)}*/}

      {/*{!loading && !(Array.isArray(data?.consumptionEnergyData?.consumptionData) && data?.consumptionEnergyData?.consumptionData.length > 0 && data?.consumptionEnergyData?.consumptionData[0]?.details?.data) && (*/}
      {/*  <>*/}
      {/*    {gettext("No data found")}<br/>*/}
      {/*    {gettext("If the data is heavy, it may still be loading.")}<br/>*/}
      {/*  </>*/}
      {/*)}*/}

      {/*{!loading && columns.length === 0 && (*/}
      {/*  <>*/}
      {/*    {gettext("No data found")}<br/>*/}
      {/*    {gettext("If the data is heavy, it may still be loading.")}<br/>*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  );
};

EnergyMonthlyStatement.propTypes = {
  onChangeEnergyStatement: PropsType.func,
  fileNameForExport: PropsType.string,
  meterType: PropsType.string.isRequired,
  year: PropsType.number.isRequired,
  month: PropsType.string.isRequired,
  columns: PropsType.array.isRequired,
  rows: PropsType.array.isRequired,
  excelData: PropsType.array,
  rowCount: PropsType.number,
  infoText: PropsType.string,
};